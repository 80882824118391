main.main {
	@extend %block;
	padding-right: 0;

	@media (min-width: 940px) {
		padding-right: 70px;
	}

	@media (min-width: 1700px) {
		padding-right: 0;
	}
}