
.ce-image {
	@extend %block;
	position: relative;

	&.img-bg {
		background: rgba(0,0,0,0.5);
		padding: 1.5rem;
	}

	a.img-link {
		@extend %block;
	}

	img {
		width: auto;
		max-width: 100%;
		height: auto;
		max-height: 100%;
		display: block;
	}

	figcaption {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
		display: block;
		background: rgba(0,0,0,0.75);
		padding: 0.5rem;
		font-weight: 400;
		font-size: 0.75rem;
	}

	&.img-fill {
		img {
			@extend %block;
		}
	}

	&.align-left {
		display: flex;
		justify-content: flex-start;
		justify-items: flex-start;
	}

	&.align-right {
		display: flex;
		justify-content: flex-end;
		justify-items: flex-end;
	}

	&.align-center {
		display: flex;
		justify-content: center;
		justify-items: center;
	}
}
