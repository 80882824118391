.ce-gallery {
	width: 100%;
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr;
	-webkit-grid-template-columns: 1fr 1fr;
	-moz-grid-template-columns: 1fr 1fr;
	
	@include for-size(md) {
		grid-template-columns: 1fr 1fr 1fr;
		-webkit-grid-template-columns: 1fr 1fr 1fr;
		-moz-grid-template-columns: 1fr 1fr 1fr;
	}
	
	@include for-size(lg) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		-webkit-grid-template-columns: 1fr 1fr 1fr 1fr;
		-moz-grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	
	@include for-size(xl) {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		-webkit-grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		-moz-grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	
	
	a.ce-gallery-image {
		@extend %block;
		position: relative;
		overflow: hidden;
		padding-top: 100%;
		background: $color--light;
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		@include transition(all 0.5s $easing--in-out);
		
		&:hover {
			opacity: 1;
			filter: grayscale(0%);
			-webkit-filter: grayscale(0%);
			@include transition(all 0.5s $easing--in-out);
		}

		img {
			width: 75%;
			height: auto;
			display: block;
			object-fit: contain;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translateX(-50%) translateY(-50%));
		}
	}
}
.chocolat-wrapper {
	z-index: 10000 !important;
	
	.chocolat-overlay {
		background: #000;
	}
}