.sidebar {
	display: none;
	top: 50%;
	right: 0;
	z-index: 9990;
	@include position(fixed);
	@include transform(translateY(-50%));

	@media (min-width: 1200px) {
		display: block;
	}

	.sidebar--inner {
		display: block;
	}

	.sb-item {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		width: 70px;
		height: 70px;

		@media (min-width: 1700px) {
			width: 90px;
			height: 90px;
		}

		img {
			display: block;
			width: 45%;
			height: auto;
		}

		span {
			width: 100%;
			font-size: 0.5rem;
			font-weight: 700;
			text-transform: uppercase;
			color: $color--light;
			margin-top: 0.35rem;
			text-align: center;

			@media (min-width: 1700px) {
				font-size: 0.75rem;
			}
		}
	}

	.sidebar--toggle {
		@extend %block-flex;
		display: none;
		justify-content: center;
		align-items: center;
		width: 60px;
		height: 60px;
		background: $color--light;
		@include shadow(box, $shadow--small);

		@media (min-width: 1700px) {
			width: 90px;
			height: 90px;
		}

		img {
			display: block;
			width: 45%;
			height: auto;
		}
	}
}