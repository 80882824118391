.logo {
	@extend %block-flex;
	justify-content: flex-start;
	position: relative;
	top: 0;
	padding: $spacing--small 0;

	@media (min-width: 940px) {
		top: -0.75rem;
	}

	img {
		display: block;
		width: auto;
		height: 65px;
		@include transform(translateX(-5px));
	}
}