.ce-text{
	@extend %block;
	line-height: 1.35;
	font-size: 0.9rem;
	font-family: $font--base-family;
	column-gap: $spacing--large;
	-webkit-column-gap: $spacing--large;
	-moz-column-gap: $spacing--large;

	@include for-size(md) {
		font-size: 1rem;
	}

	p {
		@extend %block;
		font-weight: 300;
		@include hyphens(auto);
		columns: 1;
		-webkit-columns: 1;
		-moz-columns: 1;
	}

	a {
		text-decoration: underline;
		display: inline;
		color: inherit !important;
	}

	// Headlines only in Rich Text Editor
	h3 {
		font-size: 1.25rem;

		@include for-size(sm) {
			font-size: 1.35rem;
		}

		@include for-size(md) {
			font-size: 1.45rem;
		}

		@include for-size(lg) {
			font-size: 1.55rem;
		}
	}
	h4 {
		font-size: 1.1rem;

		@include for-size(sm) {
			font-size: 1.15rem;
		}

		@include for-size(md) {
			font-size: 1.2rem;
		}

		@include for-size(lg) {
			font-size: 1.25rem;
		}
	}
	h5, h6 {
		font-size: 1rem;

		@include for-size(sm) {
			font-size: 1.025rem;
		}

		@include for-size(md) {
			font-size: 1.05rem;
		}

		@include for-size(lg) {
			font-size: 1.1rem;
		}
	}

	strong {
		font-weight: 700;
	}

	ul {
		@extend %block;
		padding: 0;

		li {
			@extend %block;
			list-style: none;
			padding: 0;
			padding-left: 1rem;
			margin-bottom: 0.25rem;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 12px;
				width: 5px;
				height: 5px;
				@include radius(100%);
				background: $color--primary;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	figure.table {
		@extend %block;

		table {
			width: 100%;
			margin-top: 0.25rem;
			border: none;
			border-collapse: collapse;
			font-size: 0.85rem;
			table-layout: fixed;

			thead {
				background: $color--primary !important;

				th {
					background: none !important;
					color: $color--light;
					padding: $spacing--small $spacing--medium;
					text-transform: uppercase;
					font-weight: 700 !important;
					text-align: left;
				}
			}

			tbody {
				tr {
					border-bottom: 1px solid $color--primary;

					th, td {
						text-align: left;
						padding: $spacing--small $spacing--medium;
						border: none;
						@include hyphens(auto);

						&:first-child {
							border-left: 0;
						}

						&:last-child {
							border-right: 0;
						}
					}

					th {
						font-weight: 700 !important;
						font-size: 1rem;
					}

					td {
						font-weight: 300;
						font-size: 1rem;
					}

					&:first-child {
						th, td {
							border-top: 0;
						}
					}

					&:last-child {
						th, td {
							border-bottom: 0;
						}
					}
				}
			}
		}
	}

	&.text-col-1 {
		p {
			columns: 1;
			-webkit-columns: 1;
			-moz-columns: 1;
		}
	}

	&.text-col-2 {
		@include for-size(sm) {
			columns: 2;
			-webkit-columns: 2;
			-moz-columns: 2;
		}
	}

	&.text-col-3 {
		@include for-size(sm) {
			columns: 2;
			-webkit-columns: 2;
			-moz-columns: 2;
		}
		@include for-size(md) {
			columns: 3;
			-webkit-columns: 3;
			-moz-columns: 3;
		}
	}

	&.text-col-4 {
		@include for-size(sm) {
			columns: 2;
			-webkit-columns: 2;
			-moz-columns: 2;
		}
		@include for-size(md) {
			columns: 3;
			-webkit-columns: 3;
			-moz-columns: 3;
		}
		@include for-size(lg) {
			columns: 4;
			-webkit-columns: 4;
			-moz-columns: 4;
		}
	}

	&.ts-small {
		font-size: 0.65rem;

		@include for-size(md) {
			font-size: 0.7rem;
		}

		@include for-size(lg) {
			font-size: 0.75rem;
		}
	}

	&.ts-large {
		font-size: 1.45rem;

		@include for-size(md) {
			font-size: 1.6rem;
		}

		@include for-size(lg) {
			font-size: 1.75rem;
		}
	}
}

.ce-text-with-image {
	@extend %block;
	line-height: 1.35;
	font-size: 0.9rem;
	font-weight: 300;
	@include hyphens(auto);
	vertical-align: top;
	clear-after: both;

	@include for-size(md) {
		font-size: 1rem;
	}

	&:after {
		width: 100%;
		display: block;
		height: 1px;
		float: left;
	}

	&.align-left {
		figure {
			float: left;
			margin-right: $spacing--medium;
		}
	}

	&.align-right {
		figure {
			float: right;
			margin-left: $spacing--medium;
		}
	}

	&.align-center {
		figure {
			width: 100%;
			@extend %block-flex;
			justify-content: center;
		}
	}

	figure {
		width: auto;
		margin-bottom: $spacing--medium;
		position: relative;

		img {
			@extend %block;
			float: left;
		}

		figcaption {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: auto;
			display: block;
			background: rgba(0,0,0,0.75);
			padding: 0.5rem;
			font-weight: 400;
			font-size: 0.75rem;
			color: $color--light;
		}
	}
}
