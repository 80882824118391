.ce-output {
	.more {
		display: none;
	}

	.ce-output-item {
		@extend %block-flex;
		align-items: stretch;
		a {
			@extend %block-flex;
			background: $color--primary-light;
			align-items: stretch;
			flex-wrap: wrap;
			@include transform(scale(1));
			@include transition(all 0.35s $easing--in-out);

			&:hover {
				@include transform(scale(1.05));
				@include transition(all 0.35s $easing--in-out);
			}

			.image {
				@extend %block;
				position: relative;
				height: 220px;

				figure.bg-image {
					@include absolute(0 0 0 0);
					width: 100%;
					height: 100%;
				}
			}

			.content {
				@extend %block;
				padding: $spacing--large;

				.ce-headline {
					margin-bottom: $spacing--medium;

					h3 {
						font-size: 1.1rem;

						@include for-size(sm) {
							font-size: 1.15rem;
						}

						@include for-size(md) {
							font-size: 1.2rem;
						}

						@include for-size(lg) {
							font-size: 1.25rem;
						}
					}
				}
				.ce-text {
					font-size: 0.9rem;

					@include for-size(md) {
						font-size: 0.95rem;
					}

					@include for-size(lg) {
						font-size: 0.95rem;
					}
				}

				.read-more {
					font-weight: 700;
					text-decoration: underline;
				}
			}
		}
	}

	.ce-output-reference-item {
		@extend %block;
		position: relative;
		padding-top: 100%;
		@include transform(scale(1));
		@include transition(all 0.35s $easing--in-out);

		&:hover {
			@include transform(scale(1.1));
			@include transition(all 0.35s $easing--in-out);
		}
		a {
			@extend %block-flex;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			align-content: center;
			flex-wrap: wrap;
			background: $color--primary-light;
			padding: $spacing--large;

			.icon{
				@extend %block-flex;
				justify-content: center;
				margin-bottom: $spacing--medium;

				img {
					width: auto;
					height: 64px;
					display: block;
				}
			}

			.content {
				@extend %block;
				.ce-headline {
					h3 {
						text-align: center;
						font-size: 1rem;
						@include hyphens(auto);

						@include for-size(sm) {
							font-size: 1.05rem;
						}

						@include for-size(md) {
							font-size: 1.1rem;
						}
					}
				}
			}
		}
	}

	&.style--preview {
		@include for-size(md) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		.more {
			grid-column: span 1;
			background: $color--primary;
			color: $color--light;
			padding: $spacing--large;
			@extend %block-flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			opacity: 1;
			@include transition(all 0.35s $easing--in-out);

			&:hover {
				opacity: 0.75;
				@include transition(all 0.35s $easing--in-out);
			}

			@include for-size(md) {
				grid-column: span 4;
			}

			@include for-size(lg) {
				grid-column: span 1;
			}

			.more-inner {
				@extend %block-flex;
				justify-content: center;
				flex-wrap: wrap;

				img {
					width: 40px;
					max-width: 100%;
					height: auto;
					display: block;
					margin-bottom: 1rem;
				}

				.label {
					width: 100%;
					display: block;
					font-size: 1rem;
					font-weight: 700;
					text-align: center;
					margin-top: 1rem;
				}
			}
		}
		.ce-output-item {
			&:first-child {
				@include for-size(md) {
					grid-column: span 4;
				}
				@include for-size(lg) {
					grid-column: span 5;
				}
				.image, .content {
					@include for-size(md) {
						width: 50%;
					}
				}
				.image {
					@include for-size(md) {
						height: auto;
					}
				}
				.content {
					@include for-size(md) {
						padding: 7rem $spacing--large;
					}

					.ce-headline {
						margin-bottom: $spacing--medium;

						h3 {
							font-size: 1.25rem;

							@include for-size(sm) {
								font-size: 1.35rem;
							}

							@include for-size(md) {
								font-size: 1.45rem;
							}

							@include for-size(lg) {
								font-size: 1.55rem;
							}
						}
					}
					.ce-text {
						font-size: 0.9rem;

						@include for-size(md) {
							font-size: 1rem;
						}

						@include for-size(lg) {
							font-size: 1.1rem;
						}
					}
				}
			}
			&:nth-child(2), &:nth-child(3) {
				@include for-size(md) {
					grid-column: span 2;
				}
			}
		}
	}
}

ul.output-pagination {
	@extend %block-flex;
	justify-content: flex-start;
	align-items: center;
	padding-top: $spacing--medium;

	li.pagination-item {
		list-style: none;
		padding: 0.5rem;
		margin: 0 calc($spacing--small * 0.25);

		&:first-child {
			margin-left: 0;
		}

		a {
			font-weight: 900;
			font-size: 1rem;
			color: $color--primary;
		}

		&.current {
			background: $color--primary;

			a {
				color: $color--light;
			}
		}
	}
}
