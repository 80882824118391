footer.footer {
	@extend %block;
	background: $color--primary;

	ul.navigation--breadcrumb, ul.navigation--info {
		@extend %block-flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			list-style: none;
			margin-right: 1.5rem;

			&:last-child {
				margin-right: 0;
			}

			a {
				color: $color--light;
				font-size: 0.9rem;
				opacity: 1;
				@include transition(all 0.35s $easing--in-out);

				&:hover {
					opacity: 0.75;
					@include transition(all 0.35s $easing--in-out);
				}
			}
		}
	}

	ul.navigation--breadcrumb {
		margin-bottom: 2rem;

		li {
			margin-right: 0.75rem;
			&.divider {
				img {
					width: 12px;
					height: auto;
				}
			}

			a {
				font-weight: 700;
			}
		}
	}
}