/// Theme helper – defines variables for a specific theme
/// @access private
/// @author dba.codes – Daniel Baumert
/// @param {Object} $themes - Object that contains all defined themes

/**
 * 1. Theme name
 * 2. Color variables
 * 3. Typography variables
 * 4. Radius variables
 * 5. Spacing variables
 * 6. Shadow variables
 * 6. Easing variables
 */

/* 1 */
$theme: default;
/* 2 */
$color--primary: theme-property($theme, colors, primary);
$color--primary-light: theme-property($theme, colors, primary-light);
$color--secondary: theme-property($theme, colors, secondary);
$color--secondary-light: theme-property($theme, colors, secondary-light);
$color--accent: theme-property($theme, colors, accent);
$color--accent-light: theme-property($theme, colors, accent-light);
$color--grey: theme-property($theme, colors, grey);
$color--grey-light: theme-property($theme, colors, grey-light);
$color--light: theme-property($theme, colors, light);
$color--dark: theme-property($theme, colors, dark);
$color--font-base: theme-property($theme, colors, font-base);
$color--font-headline: theme-property($theme, colors, font-headline);
// special colors
$color--clear: theme-property($theme, colors, clear);
$color--clear-light: theme-property($theme, colors, clear-light);
$color--attention: theme-property($theme, colors, attention);
$color--attention-light: theme-property($theme, colors, attention-light);
/* 3 */
$font--base-size: theme-property($theme, typography, base-size);
$font--base-family: theme-property($theme, typography, base-family);
$font--headline-family: theme-property($theme, typography, headline-family);
/* 4 */
$radius--small: theme-property($theme, radius, sm);
$radius--medium: theme-property($theme, radius, md);
$radius--large: theme-property($theme, radius, lg);
/* 5 */
$spacing--small: theme-property($theme, spacing, sm);
$spacing--medium: theme-property($theme, spacing, md);
$spacing--large: theme-property($theme, spacing, lg);
/* 6 */
$shadow--small: theme-property($theme, shadow, sm);
$shadow--medium: theme-property($theme, shadow, md);
$shadow--large: theme-property($theme, shadow, lg);
$shadow--menu: 0 8px 12px rgba(0, 0, 0, 0.05);
/* 7 */
$easing--in-out: cubic-bezier(0.85, 0, 0.15, 1);
$easing--in: cubic-bezier(0.55, 0, 1, 0.45);
$easing--out: cubic-bezier(0, 0.55, 0.45, 1);
