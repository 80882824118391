.ce-message-box {
	@extend %block-flex;
	align-items: stretch;
	align-content: stretch;

	&.type-note, &.type-info {
		.icon {
			background: $color--grey;
		}
		.content {
			background: $color--grey-light;
			.ce-headline, .ce-text {
				color: $color--grey;
			}
			a.link {
				background: $color--grey;
				color: $color--light;
			}
		}
	}

	&.type-info {
		.icon {
			background: $color--primary;
		}
		.content {
			background: $color--primary-light;
			.ce-headline, .ce-text {
				color: $color--primary;
			}
			a.link {
				background: $color--primary;
				color: $color--light;
			}
		}
	}

	&.type-clear {
		.icon {
			background: $color--clear;
		}
		.content {
			background: $color--clear-light;
			.ce-headline, .ce-text {
				color: $color--clear;
			}
			a.link {
				background: $color--clear;
				color: $color--light;
			}
		}
	}

	&.type-warning {
		.icon {
			background: $color--secondary;
		}
		.content {
			background: $color--secondary-light;
			.ce-headline, .ce-text {
				color: $color--secondary;
			}
			a.link {
				background: $color--secondary;
				color: $color--light;
			}
		}
	}

	&.type-attention {
		.icon {
			background: $color--attention;
		}
		.content {
			background: $color--attention-light;
			.ce-headline, .ce-text {
				color: $color--attention;
			}
			a.link {
				background: $color--attention;
				color: $color--light;
			}
		}
	}

	.icon {
		@extend %block-flex;
		width: auto;
		align-items: center;
		justify-items: center;
		padding: 1.25rem;
		font-size: 1.5rem;
		color: $color--light;

		i {
			width: 28px;
			text-align: center;
		}

		svg {
			width: 28px;
			height: 28px;
			text-align: center;
			fill: $color--light;
		}
	}

	.content {
		flex: 1;
		padding: $spacing--large;

		.ce-headline {
			margin-bottom: $spacing--small;
		}

		.ce-button-group {
			margin-top: $spacing--small;
		}
	}
}
