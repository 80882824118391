.ce-contact-person-collection {
	@extend %block;

	&.view--card, &.view--card-without-image {
		.neos-contentcollection {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: $spacing--large;

			@include for-size(sm) {
				grid-template-columns: 1fr 1fr;
			}

			@include for-size(md) {
				grid-template-columns: 1fr 1fr 1fr;
			}

			@include for-size(lg) {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}

		.ce-contact-person {
			@extend %block;
			background: $color--primary-light;
			position: relative;

			figure.image {
				@extend %block;
				padding-top: 100%;
				@include background-property(no-repeat !important, cover !important, center center !important);
				position: relative;

				span.toggle-info {
					position: absolute;
					bottom: $spacing--small;
					right: $spacing--small;
					background: $color--primary-light;
					color: $color--primary;
					padding: $spacing--small $spacing--medium;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					img {
						display: inline;
						margin-right: $spacing--small;
						height: 20px;
						width: auto;
					}

					.label {
						font-weight: 400;
						font-size: 0.9rem;
					}
				}
			}

			.content-inner {
				@extend %block;
				padding: $spacing--large $spacing--medium;

				.name, .function {
					@extend %block;
					text-align: center;
					font-weight: 700;
				}

				.name {
					color: $color--primary;
					font-size: 1.35rem;

					.shortcut {
						background: $color--primary;
						color: $color--light;
						padding: 0.125rem 0.5rem 0.2rem;
						font-weight: 700;
						font-size: 0.65rem;
						margin-left: 0.75rem;
						position: relative;
						top: -4px;
					}
				}

				.function {
					color: $color--secondary;
					text-transform: uppercase;
					font-size: 0.9rem;
					margin-top: $spacing--small;
					margin-bottom: $spacing--medium;
				}

				ul.data-list {
					@extend %block-flex;
					flex-wrap: wrap;
					justify-content: center;
					align-content: center;
					margin-top: $spacing--small;

					&.list-subjects {
						li {
							margin: 0.15rem;

							&:after {
								content: ',';
							}

							&:first-child, &:last-child {
								&:after {
									content: '';
									display: none;
								}
							}
						}
					}

					li {
						width: auto;
						list-style: none;
						margin: 0.15rem $spacing--small;
						font-size: 0.75rem;

						.label {
							font-weight: 700;
							margin-right: 0.1rem;
						}
					}
				}
			}

			.info-text-container {
				display: none;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				position: fixed;
				top: 0;
				left: 0;
				z-index: 999999;
				pointer-events: none;
				opacity: 0;
				padding-top: 90px;

				&.active {
					display: flex;
					display: -webkit-flex;
					display: -moz-flex;
					opacity: 1;
					@include transition(opacity 0.35s $easing--in-out);
				}
			}

			.info-text {
				position: relative;
				width: 100%;
				max-width: 650px;
				height: 100%;
				max-height: 100%;
				z-index: 9999;
				display: block;
				background: $color--light;
				padding: $spacing--large;
				padding-top: 0;
				@include transition(opacity 0.35s $easing--in-out);
				overflow-x: hidden;
				overflow-y: auto;
				@include shadow(box, $shadow--medium);
				pointer-events: auto;

				@include for-size(sm) {
					height: auto;
				}

				span.close-info {
					position: sticky;
					top: 0;
					left: 0;
					width: 100%;
					@extend %block-flex;
					align-items: center;
					justify-content: center;
					background: $color--light;
					color: $color--primary;
					padding: $spacing--large;
					cursor: pointer;

					img {
						display: inline;
						margin-right: $spacing--small;
						height: 20px;
						width: auto;
					}

					.label {
						font-weight: 400;
						font-size: 0.9rem;
					}
				}
			}
		}
	}

	&.view--table, &.view--table-without-image {
		.neos-contentcollection {
			@extend %block;
		}
		.ce-contact-person {
			@extend %block-flex;
			justify-content: flex-start;
			align-items: stretch;
			background: $color--primary-light;
			margin-bottom: 3px;

			&:last-child {
				margin-bottom: 0;
			}

			figure.image {
				display: inline;
				width: 70px;
				height: auto;
				min-height: 70px;
				@include background-property(no-repeat !important, cover !important, center center !important);
			}

			.content-inner {
				@extend %block-flex;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: wrap;
				padding: $spacing--medium;

				@include for-size(md) {
					padding: 0;
				}

				@include for-size(md)  {
					flex-wrap: nowrap;
				}

				.name, .function, ul.data-list {
					padding: 0;
					margin: 0.25rem 0;

					@include for-size(md) {
						padding: $spacing--medium;
					}
				}

				.name, .function {
					font-weight: 700;
					font-size: 0.9rem;
					color: $color--primary;
					width: 100%;

					@include for-size(sm) {
						width: 50%;
					}
					@include for-size(md) {
						width: 22.5%;
					}
				}

				.function {
					width: 100%;

					@include for-size(sm) {
						width: 50%;
					}
					@include for-size(md) {
						width: 17.5%;
					}
				}

				.name {
					.shortcut {
						background: $color--primary;
						color: $color--light;
						padding: 0.125rem 0.5rem 0.2rem;
						font-weight: 700;
						font-size: 0.65rem;
						margin-left: 0.75rem;
						position: relative;
						top: -2px;
					}
				}

				ul.data-list {
					@extend %inline-flex;
					flex-wrap: wrap;
					width: 100%;

					@include for-size(sm) {
						width: 50%;
					}
					@include for-size(md) {
						width: 20%;
					}

					&.list-subjects {
						li {
							width: auto;
							display: inline;
							margin: 0.15rem;

							&:after {
								content: ',';
							}

							&:first-child, &:last-child {
								&:after {
									content: '';
									display: none;
								}
							}
						}
					}

					li {
						list-style: none;
						margin: 0.15rem $spacing--small;
						font-size: 0.75rem;

						&:first-child {
							margin-left: 0;
						}

						&:last-child {
							margin-right: 0;
						}

						.label {
							font-weight: 700;
							margin-right: 0.1rem;
						}
					}
				}
			}
		}
	}

	&.view--card-without-image, &.view--table-without-image {
		.ce-contact-person {
			figure.image {
				display: none;
			}
		}
	}
}
