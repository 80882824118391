header {
	@extend %block;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9995;
	background: $color--light;
	border-bottom: 1px solid $color--primary-light;
	@include shadow(box, $shadow--menu);

	.bottom {
		align-items: center;

		@media (min-width: 1200px) {
			align-items: flex-end;
		}
	}
}
