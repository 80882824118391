 //GRID ROWS & COLUMNS
$max-columns: 12;
$max-rows: 12;
//GRID MAX-WIDTH
$max-width: 1600px;

//GRID GAP
$gap-grid: 0;
$gap-columns: 2rem;
$gap-rows: 2rem;

//GRID BOUNDARY
$sm-boundary: 600px;
$md-boundary: 900px;
$lg-boundary: 1200px;
$xl-boundary: 1500px;