.ce-maps {
	@extend %block;

	.maps-frame {
		@extend %block;
		position: relative;
		padding-top: 56.25% !important;

		iframe {
			@extend %block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}