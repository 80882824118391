.navigation {
	@extend %block;
	padding-top: 0;

	@media (min-width: 1200px) {
		padding-top: $spacing--small;
	}

	ul.navigation--meta {
		display: none;
		margin-bottom: 0;
		@include transition(all 0.35s $easing--in-out);

		@media (min-width: 1200px) {
			display: flex;
			display: -webkit-flex;
			display: -moz-flex;
			justify-content: flex-end;
			width: 100%;
		}

		li {
			list-style: none;
			display: inline;
			margin-right: 0.25rem;

			&:first-child {
				a {
					padding-left: 0;
				}
			}

			&:last-child {
				margin-right: 0;
				a {
					padding-right: 0;
				}
			}

			a {
				@extend %block;
				font-size: 1rem;
				padding: $spacing--small;
				@include transition(color 0.35s $easing--in-out);

				svg {
					@extend %block;
					width: 17px;
					height: 17px;
					fill: $color--primary;
				}

				&:hover {
					color: $color--secondary;
					@include transition(color 0.35s $easing--in-out);
				}
			}
		}
	}

	ul.navigation--main {
		display: none;

		@media (min-width: 1200px) {
			display: flex;
			display: -webkit-flex;
			display: -moz-flex;
			justify-content: flex-end;
			width: 100%;
		}

		li {
			list-style: none;
			display: inline;

			&:hover {
				.navigation--mega-menu {
					display: block;

					.row {
						@include transform(translateX(0px));
						@include transition(all 0.35s $easing--in-out);
					}
				}

				>a {
					color: $color--secondary;
					@include transition(color 0.35s $easing--in-out);

					span {
						&:after {
							background: $color--secondary;
							@include transition(background 0.35s $easing--in-out);
						}
					}
				}
			}

			&.current, &.active {
				>a {
					span {
						&:after {
							opacity: 1;
						}
					}
				}
			}

			&:first-child {
				a {
					padding-left: 0;
				}
			}

			&:last-child {
				a {
					padding-right: 0;
				}
			}

			a {
				@extend %block;
				font-size: 1rem;
				color: $color--primary;
				padding: $spacing--small;
				padding-bottom: $spacing--medium;
				@include transition(color 0.35s $easing--in-out);
				@include transition(all 0.35s $easing--in-out);

				span {
					position: relative;

					&:after {
						content: '';
						opacity: 0;
						width: 100%;
						height: 2px;
						position: absolute;
						bottom: -8px;
						left: 0;
						background: $color--primary;
						@include transition(background 0.35s $easing--in-out);
					}
				}
			}

			.navigation--mega-menu {
				display: none;
				width: 100vw;
				position: absolute;
				top: 108px;
				left: 0;
				z-index: 9998;
				background: $color--light;
				padding: 1rem 0;
				overflow: hidden;
				@include shadow(box, $shadow--menu);
				@include transition(all 0.35s $easing--in-out);

				figure.abstract {
					width: 40%;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;

					.circle {
						width: 1200px;
						height: 1200px;
						position: absolute;
						bottom: 0;
						right: 0;
						z-index: 2;
						background: $color--primary-light;
						@include radius(100%);
						@include transform(translateX(20%) translateY(60%));
					}

					.icon-01 {
						width: 120px;
						position: absolute;
						top: 14%;
						left: 15%;
						z-index: 9999;
					}

					.icon-02 {
						width: 120px;
						position: absolute;
						bottom: 18%;
						left: 36%;
						z-index: 9999;
					}
				}

				.row {
					position: relative;
					z-index: 5;
					@include transform(translateX(-100px));
					@include transition(all 0.35s $easing--in-out);
				}

				.mega-menu--headline {
					position: relative;
					font-size: 1.5rem;
					font-weight: 900;
					text-transform: uppercase;
					padding-bottom: 0.75rem;

					&:after {
						content: '';
						width: 60px;
						height: 4px;
						position: absolute;
						bottom: 0;
						left: 0;
						background: $color--primary;
					}
				}

				ul {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-gap: 1rem;

					li {
						display: inline;
						margin-right: 0;

						a {
							padding: $spacing--small 0;
						}
					}
				}
			}
		}
	}
	.toggle-menu {
		display: block;
		// height: 65px;
		width: 50px;
		cursor: pointer;
		// position: relative;
		// top: 4px;

		@media (min-width: 1200px) {
			display: none;
		}

		@media (hover: hover) {
			&:hover {
				span {
					&:nth-child(2) {
						width: 50%;
						left: 25%;
						@include transition(all 0.35s $easing--in-out);
					}
				}
			}
		}

		&.active {
			span {
				&:first-child {
					@include transform(rotate(45deg) translateX(10px) translateY(10px));
					@include transition(all 0.35s $easing--in-out);
				}

				&:nth-child(2) {
					opacity: 0;
					width: 0;
					left: 50%;
					@include transition(all 0.35s $easing--in-out);
				}

				&:last-child {
					@include transform(rotate(-45deg) translateX(10px) translateY(-10px));
					@include transition(all 0.35s $easing--in-out);
				}
			}
		}

		span {
			display: block;
			width: 100%;
			height: 2px;
			position: relative;
			background: $color--primary;

			&:first-child {
				margin-top: 0px;
				@include transform(rotate(0deg) translateX(0) translateY(0));
				@include transition(all 0.35s $easing--in-out);
			}

			&:nth-child(2) {
				opacity: 1;
				width: 100%;
				left: 0;
				margin-top: 12px;
				@include transition(all 0.35s $easing--in-out);
			}

			&:last-child {
				margin-top: 12px;
				@include transform(rotate(0deg) translateX(0) translateY(0));
				@include transition(all 0.35s $easing--in-out);
			}
		}
	}
}

nav.oc-navigation {
	opacity: 0;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9990;
	background: $color--light;
	padding: $spacing--medium;
	overflow-y: auto;
	pointer-events: none;
	@include transform(translateX(100%));
	@include transition(all 0.35s $easing--in-out);

	&.show {
		opacity: 1;
		pointer-events: auto;
		@include transform(translateX(0));
		@include transition(all 0.35s $easing--in-out);
	}

	.sidebar {
		@extend %block-flex;
		align-items: stretch;
		position: static;
		top: 0;
		right: 0;
		margin-top: 90px;
		margin-bottom: 3rem;
		justify-items: stretch;
		@include transform(translateY(0));

		.sb-item {
			display: inline-flex;
			display: -webkit-inline-flex;
			flex-wrap: wrap;
			justify-content: center;
			align-content: center;
			width: 50%;
			height: auto;
			padding: $spacing--small;

			@media (min-width: 500px) {
				width: 25%;
				padding: $spacing--medium;
			}

			img {
				display: block;
				width: auto;
				height: 45px;
			}

			span {
				width: 100%;
				font-size: 0.75rem;
				font-weight: 700;
				text-transform: uppercase;
				color: $color--light;
				margin-top: 0.35rem;
				text-align: center;
			}
		}
	}

	ul.navigation--mobile {

		li {
			@extend %block;
			list-style: none;
			margin-bottom: $spacing--small;

			&.current, &.active {
				> a {
					font-weight: 700;
				}
			}

			a {
				@extend %block;
				font-size: 1.2rem;
				font-weight: 400;
				text-transform: uppercase;
				color: $color--primary;
				padding: 0.25em;
			}

			ul {
				display: none;
			}
		}
	}


	ul.navigation--meta {
		display: none;
		margin-bottom: 0;
		@include transition(all 0.35s $easing--in-out);

		@media (min-width: 1200px) {
			display: flex;
			display: -webkit-flex;
			display: -moz-flex;
			justify-content: flex-end;
			width: 100%;
		}

		li {
			list-style: none;
			display: inline;
			margin-right: 0.25rem;

			&:first-child {
				a {
					padding-left: 0;
				}
			}

			&:last-child {
				margin-right: 0;

				a {
					padding-right: 0;
				}
			}

			a {
				@extend %block;
				font-size: 1rem;
				padding: $spacing--small;
				@include transition(color 0.35s $easing--in-out);

				&:hover {
					color: $color--secondary;
					@include transition(color 0.35s $easing--in-out);
				}
			}
		}
	}
}
