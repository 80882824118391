// radius
$radiusgroup: map-deep-get($themes, default, radius);
@each $radius, $map in $radiusgroup {
	.radius--#{$radius} {
		@include radius($map);
	}
}
// shadow
$shadowgroup: map-deep-get($themes, default, shadow);
@each $shadow, $map in $shadowgroup {
	.shadow-text--#{$shadow} {
		@include shadow(text, $map);
	}
	.shadow-box--#{$shadow} {
		@include shadow(box, $map);
	}
}
// font color
.fc--primary {
	color: $color--primary;
}
.fc--primary-light {
	color: $color--primary-light;
}
.fc--secondary {
	color: $color--secondary;
}
.fc--secondary-light {
	color: $color--secondary-light;
}
.fc--accent {
	color: $color--accent;
}
.fc--accent-light {
	color: $color--accent-light;
}
.fc--grey-light {
	color: $color--grey-light;
}
.fc--grey {
	color: $color--grey;
}
.fc--light {
	color: $color--light;
}
.fc--dark {
	color: $color--dark;
}
.fc--attention {
	color: $color--attention;
}
.fc--attention-light {
	color: $color--attention-light;
}
.fc--clear {
	color: $color--clear;
}
.fc--clear-light {
	color: $color--clear-light;
}
// background color
.bg--primary {
	background-color: $color--primary;
}
.bg--primary-light {
	background-color: $color--primary-light;
}
.bg--secondary {
	background-color: $color--secondary;
}
.bg--secondary-light {
	background-color: $color--secondary-light;
}
.bg--accent {
	background-color: $color--accent;
}
.bg--accent-light {
	background-color: $color--accent-light;
}
.bg--grey-light {
	background-color: $color--grey-light;
}
.bg--grey {
	background-color: $color--grey;
}
.bg--light {
	background-color: $color--light;
}
.bg--dark {
	background-color: $color--dark;
}
.bg--attention {
	background-color: $color--attention;
}
.bg--attention-light {
	background-color: $color--attention-light;
}
.bg--clear {
	background-color: $color--clear;
}
.bg--clear-light {
	background-color: $color--clear-light;
}
// min-height
.min-height--25 {
	min-height: 25vh;
}
.min-height--33 {
	min-height: 33.333vh;
}
.min-height--50 {
	min-height: 50vh;
}
.min-height--66 {
	min-height: 66.666vh;
}
.min-height--75 {
	min-height: 75vh;
}
.min-height--100 {
	min-height: 100vh;
}
//space
.space--top {
	margin-top: $spacing--medium;
}
.space--bottom {
	margin-bottom: $spacing--medium;
}
.space--left {
	margin-left: $spacing--medium;
}
.space--right {
	margin-right: $spacing--medium;
}
.no-space--top {
	margin-top: 0 !important;
	padding-top: 0 !important;
}
.no-space--bottom {
	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
}
.no-space--left {
	margin-left: 0 !important;
	padding-left: 0 !important;
}
.no-space--right {
	margin-right: 0 !important;
	padding-right: 0 !important;
}