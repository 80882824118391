.site-header {
	@extend %block;
	position: relative;
	margin-top: 85px;

	@include for-size(md) {
		margin-top: 88px;
	}

	@include for-size(lg) {
		margin-top: 108px;
	}
	
	.banner {
		@extend %block;
		@include background-property(no-repeat !important, cover !important, center center !important);

		&.wh100 {
			height: 100vh;
		}

		&.wh75 {
			height: 75vh;
		}

		&.wh66 {
			height: 66.666vh;
		}

		&.wh50 {
			height: 50vh;
		}

		&.wh33 {
			height: 33.333vh;
		}

		&.wh25 {
			height: 25vh;
		}

		&.wh0 {
			height: 0vh;
		}
	}

	&.has--icon {
		.icon-container {
			@extend %block;
			position: absolute;
			bottom: 0;
			left: 0;
			@include transform(translateY(50%));

			.icon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100px;
				height: 100px;
				background: $color--primary-light;
				@include radius(100%);

				img {
					display: block;
					width: auto;
					height: 64px;
				}
			}
		}
	}

	.icon {
		display: none;
	}

	.site-title--home {
		.ce-headline {
			text-transform: uppercase;
			h1 {
				span {
					@extend %block;
					text-align: center;

					&.site-title--top {
						color: $color--secondary;
					}
					&.site-title--bottom {
						color: $color--accent;
					}
				}
			}
		}

		.ce-text {
			margin-top: $spacing--medium;
		}
	}
}