.ce-slider {
	@extend %block;
	position: relative;

	&.wh100 {
		.slider-item {
			min-height: 100vh;
		}
	}

	&.wh75 {
		min-height: 75vh;
		.slider-item {
			min-height: 75vh;
		}
	}

	&.wh66 {
		min-height: 66.666vh;
		.slider-item {
			min-height: 66.666vh;
		}
	}

	&.wh50 {
		min-height: 50vh;
		.slider-item {
			min-height: 50vh;
		}
	}

	&.wh33 {
		min-height: 33.333vh;
		.slider-item {
			min-height: 33.333vh;
		}
	}

	&.wh25 {
		min-height: 25vh;
		.slider-item {
			min-height: 25vh;
		}
	}

	&.wh0 {
		min-height: 0vh;
		.slider-item {
			min-height: 0vh;
		}
	}

	.slider-item {
		@extend %block;
		@include background-size(cover !important);
		background-repeat: no-repeat !important;
		background-position: center center !important;
		position: relative;
	}

	.swiper-button-prev, .swiper-button-next {
		color: $color--light;
		outline: none;
		border: none;

		&:hover {
			color: $color--primary;
		}
	}

	.swiper-button-prev {
		left: 20px;
	}

	.swiper-button-next {
		right: 20px;

		@media (min-width: 940px) {
			right: 110px;
		}
	}

	.swiper-pagination {
		.swiper-pagination-bullet {
			color: $color--light;
			cursor: pointer;
		}

		.swiper-pagination-bullet-active {
			color: $color--primary;
		}
	}
}

