@import "velvet-settings";

//MIXINS
@mixin for-size($range) {
	@if $range == xs {
		@content;
	}
	@else if $range == xs-only {
		@media (max-width: $sm-boundary - 1) {
			@content;
		}
	}
	@else if $range == sm {
		@media (min-width: $sm-boundary) {
			@content;
		}
	}
	@else if $range == sm-only {
		@media (min-width: $sm-boundary) and (max-width: $md-boundary - 1) {
			@content;
		}
	}
	@else if $range == md {
		@media (min-width: $md-boundary) {
			@content;
		}
	}
	@else if $range == md-only {
		@media (min-width: $md-boundary) and (max-width: $lg-boundary - 1) {
			@content;
		}
	}
	@else if $range == lg {
		@media (min-width: $lg-boundary) {
			@content;
		}
	}
	@else if $range == lg-only {
		@media (min-width: $lg-boundary) and (max-width: $xl-boundary - 1) {
			@content;
		}
	}
	@else if $range == xl {
		@media (min-width: $xl-boundary) {
			@content;
		}
	}
}

@mixin box-sizing($values) {
	box-sizing: $values;
	-moz-box-sizing: $values;
	-webkit-box-sizing: $values;
	-o-box-sizing: $values;
}

//VELVET GRID
* {
	margin: 0;
	padding: 0;
	text-decoration: none;
	outline: none;
	@include box-sizing(border-box);
}

.v-grid {
	display: grid;
	grid-column-gap: $gap-columns;
	grid-row-gap: $gap-rows;
	width: 100%;
	max-width: $max-width;
	height: auto;
	margin: 0 auto;
	padding: $gap-grid;
	grid-template-rows: auto;
	
	
	&[data-flow='column'] {
		grid-auto-flow: column;
	}
	
	&[data-flow='row'] {
		grid-auto-flow: row;
	}
	
	&[data-max-width='disable'] {
		max-width: none !important;
	}
	
	&[data-gap-grid='disable'] {
		padding: 0 !important;
	}
	
	&[data-gap-column='disable'] {
		grid-column-gap: 0 !important;
	}
	
	&[data-gap-row='disable'] {
		grid-row-gap: 0 !important;
	}
	
	//XS – EVENLY COLUMNS
	@for $i from 1 through $max-columns {
		&[data-xs-columns='#{$i}'] {
			grid-template-columns: repeat($i, 1fr);
		}
	}
	
	//XS – EVENLY ROWS
	@for $i from 1 through $max-rows {
		&[data-xs-rows='#{$i}'] {
			grid-template-rows: repeat($i, 1fr);
		}
	}
	
	//XS – ALIGN ITEMS
	&[data-xs-align-items='stretch'] {
		align-items: stretch;
	}
	
	&[data-xs-align-items='center'] {
		align-items: center;
	}
	
	&[data-xs-align-items='start'] {
		align-items: start;
	}
	
	&[data-xs-align-items='end'] {
		align-items: end;
	}
	//XS – JUSTIFY ITEMS
	&[data-xs-justify-items='stretch'] {
		justify-items: stretch;
	}
	
	&[data-xs-justify-items='center'] {
		justify-items: center;
	}
	
	&[data-xs-justify-items='start'] {
		justify-items: start;
	}
	
	&[data-xs-justify-items='end'] {
		justify-items: end;
	}
	
	@include for-size(sm) {
		
		//SM – EVENLY COLUMNS
		@for $i from 1 through $max-columns {
			&[data-sm-columns='#{$i}'] {
				grid-template-columns: repeat($i, 1fr);
			}
		}
		
		//SM – EVENLY ROWS
		@for $i from 1 through $max-rows {
			&[data-sm-rows='#{$i}'] {
				grid-template-rows: repeat($i, 1fr);
			}
		}
		
		//SM – ALIGN ITEMS
		&[data-sm-align-items='stretch'] {
			align-items: stretch;
		}
		
		&[data-sm-align-items='center'] {
			align-items: center;
		}
		
		&[data-sm-align-items='start'] {
			align-items: start;
		}
		
		&[data-sm-align-items='end'] {
			align-items: end;
		}
		//SM – JUSTIFY ITEMS
		&[data-sm-justify-items='stretch'] {
			justify-items: stretch;
		}
		
		&[data-sm-justify-items='center'] {
			justify-items: center;
		}
		
		&[data-sm-justify-items='start'] {
			justify-items: start;
		}
		
		&[data-sm-justify-items='end'] {
			justify-items: end;
		}
		
	};
	
	@include for-size(md) {
		
		//MD – EVENLY COLUMNS
		@for $i from 1 through $max-columns {
			&[data-md-columns='#{$i}'] {
				grid-template-columns: repeat($i, 1fr);
			}
		}
		
		//MD – EVENLY ROWS
		@for $i from 1 through $max-rows {
			&[data-md-rows='#{$i}'] {
				grid-template-rows: repeat($i, 1fr);
			}
		}
		
		//MD – ALIGN ITEMS
		&[data-md-align-items='stretch'] {
			align-items: stretch;
		}
		
		&[data-md-align-items='center'] {
			align-items: center;
		}
		
		&[data-md-align-items='start'] {
			align-items: start;
		}
		
		&[data-md-align-items='end'] {
			align-items: end;
		}
		//MD – JUSTIFY ITEMS
		&[data-md-justify-items='stretch'] {
			justify-items: stretch;
		}
		
		&[data-md-justify-items='center'] {
			justify-items: center;
		}
		
		&[data-md-justify-items='start'] {
			justify-items: start;
		}
		
		&[data-md-justify-items='end'] {
			justify-items: end;
		}
		
	};
	
	@include for-size(lg) {
		
		//LG – EVENLY COLUMNS
		@for $i from 1 through $max-columns {
			&[data-lg-columns='#{$i}'] {
				grid-template-columns: repeat($i, 1fr);
			}
		}
		
		//LG – EVENLY ROWS
		@for $i from 1 through $max-rows {
			&[data-lg-rows='#{$i}'] {
				grid-template-rows: repeat($i, 1fr);
			}
		}
		
		//LG – ALIGN ITEMS
		&[data-lg-align-items='stretch'] {
			align-items: stretch;
		}
		
		&[data-lg-align-items='center'] {
			align-items: center;
		}
		
		&[data-lg-align-items='start'] {
			align-items: start;
		}
		
		&[data-lg-align-items='end'] {
			align-items: end;
		}
		//LG – JUSTIFY ITEMS
		&[data-lg-justify-items='stretch'] {
			justify-items: stretch;
		}
		
		&[data-lg-justify-items='center'] {
			justify-items: center;
		}
		
		&[data-lg-justify-items='start'] {
			justify-items: start;
		}
		
		&[data-lg-justify-items='end'] {
			justify-items: end;
		}
		
	};
	
	@include for-size(xl) {
		
		//XL – EVENLY COLUMNS
		@for $i from 1 through $max-columns {
			&[data-xl-columns='#{$i}'] {
				grid-template-columns: repeat($i, 1fr);
			}
		}
		
		//XL – EVENLY ROWS
		@for $i from 1 through $max-rows {
			&[data-xl-rows='#{$i}'] {
				grid-template-rows: repeat($i, 1fr);
			}
		}
		
		//XL – ALIGN ITEMS
		&[data-xl-align-items='stretch'] {
			align-items: stretch;
		}
		
		&[data-xl-align-items='center'] {
			align-items: center;
		}
		
		&[data-xl-align-items='start'] {
			align-items: start;
		}
		
		&[data-xl-align-items='end'] {
			align-items: end;
		}
		//XL – JUSTIFY ITEMS
		&[data-xl-justify-items='stretch'] {
			justify-items: stretch;
		}
		
		&[data-xl-justify-items='center'] {
			justify-items: center;
		}
		
		&[data-xl-justify-items='start'] {
			justify-items: start;
		}
		
		&[data-xl-justify-items='end'] {
			justify-items: end;
		}
		
	};
}

.v-cell {
	
	//XS – COLUMN SPAN
	@for $i from 1 through $max-columns {
		&[data-xs-column-span='#{$i}'] {
			grid-column: span $i;
		}
	}
	
	//XS COLUMN START
	@for $i from 1 through $max-columns {
		&[data-xs-column-start='#{$i}'] {
			grid-column-start: $i;
		}
	}
	&[data-xs-column-start='first'] {
		grid-column-start: first;
	}
	
	//XS COLUMN END
	@for $i from 1 through $max-columns {
		&[data-xs-column-end='#{$i}'] {
			grid-column-end: $i;
		}
	}
	&[data-xs-column-end='end'] {
		grid-column-end: end;
	}
	
	//XS – ROW SPAN
	@for $i from 1 through $max-rows {
		&[data-xs-row-span='#{$i}'] {
			grid-row: span $i;
		}
	}
	
	//XS ROW START
	@for $i from 1 through $max-columns {
		&[data-xs-row-start='#{$i}'] {
			grid-row-start: $i;
		}
	}
	
	//XS ROW END
	@for $i from 1 through $max-columns {
		&[data-xs-row-end='#{$i}'] {
			grid-row-end: $i;
		}
	}
	
	//XS – ALIGN ITEMS
	&[data-xs-align-self='stretch'] {
		align-self: stretch;
	}
	
	&[data-xs-align-self='center'] {
		align-self: center;
	}
	
	&[data-xs-align-self='start'] {
		align-self: start;
	}
	
	&[data-xs-align-self='end'] {
		align-self: end;
	}
	//XS – JUSTIFY ITEMS
	&[data-xs-justify-self='stretch'] {
		justify-self: stretch;
	}
	
	&[data-xs-justify-self='center'] {
		justify-self: center;
	}
	
	&[data-xs-justify-self='start'] {
		justify-self: start;
	}
	
	&[data-xs-justify-self='end'] {
		justify-self: end;
	}
	
	@include for-size(sm) {
		
		//SM – COLUMN SPAN
		@for $i from 1 through $max-columns {
			&[data-sm-column-span='#{$i}'] {
				grid-column: span $i;
			}
		}
		
		//SM COLUMN START
		@for $i from 1 through $max-columns {
			&[data-sm-column-start='#{$i}'] {
				grid-column-start: $i;
			}
		}
		&[data-sm-column-start='first'] {
			grid-column-start: first;
		}
		
		//SM COLUMN END
		@for $i from 1 through $max-columns {
			&[data-sm-column-end='#{$i}'] {
				grid-column-end: $i;
			}
		}
		&[data-sm-column-end='end'] {
			grid-column-end: end;
		}
		
		//SM – ROW SPAN
		@for $i from 1 through $max-rows {
			&[data-sm-row-span='#{$i}'] {
				grid-row: span $i;
			}
		}
		
		//SM ROW START
		@for $i from 1 through $max-columns {
			&[data-sm-row-start='#{$i}'] {
				grid-row-start: $i;
			}
		}
		
		//SM ROW END
		@for $i from 1 through $max-columns {
			&[data-sm-row-end='#{$i}'] {
				grid-row-end: $i;
			}
		}
		
		//SM – ALIGN ITEMS
		&[data-sm-align-self='stretch'] {
			align-self: stretch;
		}
		
		&[data-sm-align-self='center'] {
			align-self: center;
		}
		
		&[data-sm-align-self='start'] {
			align-self: start;
		}
		
		&[data-sm-align-self='end'] {
			align-self: end;
		}
		//SM – JUSTIFY ITEMS
		&[data-sm-justify-self='stretch'] {
			justify-self: stretch;
		}
		
		&[data-sm-justify-self='center'] {
			justify-self: center;
		}
		
		&[data-sm-justify-self='start'] {
			justify-self: start;
		}
		
		&[data-sm-justify-self='end'] {
			justify-self: end;
		}
		
	};
	
	@include for-size(md) {
		
		//MD – COLUMN SPAN
		@for $i from 1 through $max-columns {
			&[data-md-column-span='#{$i}'] {
				grid-column: span $i;
			}
		}
		
		//MD COLUMN START
		@for $i from 1 through $max-columns {
			&[data-md-column-start='#{$i}'] {
				grid-column-start: $i;
			}
		}
		&[data-md-column-start='first'] {
			grid-column-start: first;
		}
		
		//MD COLUMN END
		@for $i from 1 through $max-columns {
			&[data-md-column-end='#{$i}'] {
				grid-column-end: $i;
			}
		}
		&[data-md-column-end='end'] {
			grid-column-end: end;
		}
		
		//MD – ROW SPAN
		@for $i from 1 through $max-rows {
			&[data-md-row-span='#{$i}'] {
				grid-row: span $i;
			}
		}
		
		//MD ROW START
		@for $i from 1 through $max-columns {
			&[data-md-row-start='#{$i}'] {
				grid-row-start: $i;
			}
		}
		
		//MD ROW END
		@for $i from 1 through $max-columns {
			&[data-md-row-end='#{$i}'] {
				grid-row-end: $i;
			}
		}
		
		//MD – ALIGN ITEMS
		&[data-md-align-self='stretch'] {
			align-self: stretch;
		}
		
		&[data-md-align-self='center'] {
			align-self: center;
		}
		
		&[data-md-align-self='start'] {
			align-self: start;
		}
		
		&[data-md-align-self='end'] {
			align-self: end;
		}
		//MD – JUSTIFY ITEMS
		&[data-md-justify-self='stretch'] {
			justify-self: stretch;
		}
		
		&[data-md-justify-self='center'] {
			justify-self: center;
		}
		
		&[data-md-justify-self='start'] {
			justify-self: start;
		}
		
		&[data-md-justify-self='end'] {
			justify-self: end;
		}
		
	};
	
	@include for-size(lg) {
		
		//LG – COLUMN SPAN
		@for $i from 1 through $max-columns {
			&[data-lg-column-span='#{$i}'] {
				grid-column: span $i;
			}
		}
		
		//LG COLUMN START
		@for $i from 1 through $max-columns {
			&[data-lg-column-start='#{$i}'] {
				grid-column-start: $i;
			}
		}
		&[data-lg-column-start='first'] {
			grid-column-start: first;
		}
		
		//LG COLUMN END
		@for $i from 1 through $max-columns {
			&[data-lg-column-end='#{$i}'] {
				grid-column-end: $i;
			}
		}
		&[data-lg-column-end='end'] {
			grid-column-end: end;
		}
		
		//LG – ROW SPAN
		@for $i from 1 through $max-rows {
			&[data-lg-row-span='#{$i}'] {
				grid-row: span $i;
			}
		}
		
		//LG ROW START
		@for $i from 1 through $max-columns {
			&[data-lg-row-start='#{$i}'] {
				grid-row-start: $i;
			}
		}
		
		//LG ROW END
		@for $i from 1 through $max-columns {
			&[data-lg-row-end='#{$i}'] {
				grid-row-end: $i;
			}
		}
		
		//LG – ALIGN ITEMS
		&[data-lg-align-self='stretch'] {
			align-self: stretch;
		}
		
		&[data-lg-align-self='center'] {
			align-self: center;
		}
		
		&[data-lg-align-self='start'] {
			align-self: start;
		}
		
		&[data-lg-align-self='end'] {
			align-self: end;
		}
		//LG – JUSTIFY ITEMS
		&[data-lg-justify-self='stretch'] {
			justify-self: stretch;
		}
		
		&[data-lg-justify-self='center'] {
			justify-self: center;
		}
		
		&[data-lg-justify-self='start'] {
			justify-self: start;
		}
		
		&[data-lg-justify-self='end'] {
			justify-self: end;
		}
		
	};
	
	@include for-size(xl) {
		
		//XL – COLUMN SPAN
		@for $i from 1 through $max-columns {
			&[data-xl-column-span='#{$i}'] {
				grid-column: span $i;
			}
		}
		
		//XL COLUMN START
		@for $i from 1 through $max-columns {
			&[data-xl-column-start='#{$i}'] {
				grid-column-start: $i;
			}
		}
		&[data-xl-column-start='first'] {
			grid-column-start: first;
		}
		
		//XL COLUMN END
		@for $i from 1 through $max-columns {
			&[data-xl-column-end='#{$i}'] {
				grid-column-end: $i;
			}
		}
		&[data-xl-column-end='end'] {
			grid-column-end: end;
		}
		
		//XL – ROW SPAN
		@for $i from 1 through $max-rows {
			&[data-xl-row-span='#{$i}'] {
				grid-row: span $i;
			}
		}
		
		//XL ROW START
		@for $i from 1 through $max-columns {
			&[data-xl-row-start='#{$i}'] {
				grid-row-start: $i;
			}
		}
		
		//XL ROW END
		@for $i from 1 through $max-columns {
			&[data-xl-row-end='#{$i}'] {
				grid-row-end: $i;
			}
		}
		
		//XL – ALIGN ITEMS
		&[data-xl-align-self='stretch'] {
			align-self: stretch;
		}
		
		&[data-xl-align-self='center'] {
			align-self: center;
		}
		
		&[data-xl-align-self='start'] {
			align-self: start;
		}
		
		&[data-xl-align-self='end'] {
			align-self: end;
		}
		//XL – JUSTIFY ITEMS
		&[data-xl-justify-self='stretch'] {
			justify-self: stretch;
		}
		
		&[data-xl-justify-self='center'] {
			justify-self: center;
		}
		
		&[data-xl-justify-self='start'] {
			justify-self: start;
		}
		
		&[data-xl-justify-self='end'] {
			justify-self: end;
		}
		
	};
}
