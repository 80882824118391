.ce-headline {
	@extend %block;
	font-family: $font--headline-family;
	
	h1, h2, h3, h4, h5, h6 {
		@extend %block;
		font-weight: 400;
	}

	h1 {
		font-size: 1.85rem;
		
		@include for-size(sm) {
			font-size: 2rem;
		}
		
		@include for-size(md) {
			font-size: 2.25rem;
		}
		
		@include for-size(lg) {
			font-size: 2.5rem;
		}
		
		&.big {
			font-size: 3.75rem;
			
			@include for-size(sm) {
				font-size: 4rem;
			}
			
			@include for-size(md) {
				font-size: 4.25rem;
			}
			
			@include for-size(lg) {
				font-size: 4.5rem;
			}
		}
	}
	h2 {
		font-size: 1.4rem;
		
		@include for-size(sm) {
			font-size: 1.55rem;
		}
		
		@include for-size(md) {
			font-size: 1.75rem;
		}
		
		@include for-size(lg) {
			font-size: 1.95rem;
		}
	}
	h3 {
		font-size: 1.25rem;
		
		@include for-size(sm) {
			font-size: 1.35rem;
		}
		
		@include for-size(md) {
			font-size: 1.45rem;
		}
		
		@include for-size(lg) {
			font-size: 1.55rem;
		}
	}
	h4 {
		font-size: 1.1rem;
		
		@include for-size(sm) {
			font-size: 1.15rem;
		}
		
		@include for-size(md) {
			font-size: 1.2rem;
		}
		
		@include for-size(lg) {
			font-size: 1.25rem;
		}
	}
	h5, h6 {
		font-size: 1rem;
		
		@include for-size(sm) {
			font-size: 1.025rem;
		}
		
		@include for-size(md) {
			font-size: 1.05rem;
		}
		
		@include for-size(lg) {
			font-size: 1.1rem;
		}
	}
	strong {
		font-weight: 700;
	}
}