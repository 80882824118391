
@font-face {
    font-family: 'meta-pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/FFMetaProRegular.TTF');
}

@font-face {
    font-family: 'meta-pro';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/FFMetaProBold.TTF');
}

@font-face {
    font-family: 'meta-pro';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/FFMetaProBlk.TTF');
}
