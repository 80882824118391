.cookie-consent {
	position: fixed;
	bottom: 0;
	left: 50%;
	@include transform(translateX(-50%) translateY(0));
	z-index: 9999;
	width: 100%;
	max-width: 750px;
	background: $color--light;
	padding: $spacing--medium;
	@include shadow(box, $shadow--large);
	pointer-events: none;
	opacity: 1;
	@include transition(all 0.5s $easing--in-out);

	&.hide {
		@include transform(translateX(-50%) translateY(100%));
		opacity: 0.5;
		@include transition(all 0.5s $easing--in-out);
	}

	.cc-title, .cc-text {
		@extend %block;
	}

	.cc-title {
		margin-bottom: $spacing--small;
		font-size: 1.2rem;
		font-weight: 700;
	}

	.cc-text {
		font-size: 0.9rem;
		@include hyphens(auto);
	}
}