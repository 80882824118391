body {
	background: $color--light;
	font-size: $font--base-size;
	font-family: $font--base-family;
	color: $color--font-base;
	font-weight: 400;
	overflow-x: hidden;
}

.row.stretch {
	>.col {
		display: flex;
		align-items: stretch;

		>.neos-contentcollection {
			display: flex;
			align-items: stretch;
		}
	}
}