.ce-calendar {
	@extend %block;

	.event--container {

		@media (max-width: 500px) {
			&.col {
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}
		}
	}

	.event {
		@extend %block-flex;
		align-items: stretch;
		align-content: stretch;
		margin-bottom: $spacing--small;

		&:last-child {
			margin-bottom: 0;
		}

		.event--date {
			width: 25%;
			background: $color--primary;
			padding: $spacing--medium 0.25rem;
			color: $color--primary-light;

			span.date--day, span.date--month, span.date--year {
				@extend %block;
				text-align: center;
				font-weight: 700;
				line-height: 1;
			}

			span.date--day {
				font-size: 1.8rem;
				margin-bottom: $spacing--small;
				margin-top: -5px;
			}

			span.date--month, span.date--year {
				font-size: 0.8rem;
			}
		}

		.event--info {
			width: 75%;
			background: $color--primary-light;
			padding: $spacing--medium;
			color: $color--primary;

			.info--title, .info--data {
				@extend %block;
				@include hyphens(auto);
			}

			.info--title {
				font-size: 1.1rem;
				font-weight: 700;
				margin-bottom: 0.5rem;
			}

			.info--data {
				font-size: 0.8rem;
				margin-top: 0.25rem;

				.label {
					display: inline-block;
					width: 35px;
					padding-right: 0.25rem;
					font-weight: 700;
				}
			}
		}
	}

	.ce-button {
		width: 100%;
		position: relative;
		top: 10px;

		a {
			@extend %block-flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			@include radius(0);
			text-align: left;
			padding: $spacing--medium;

			.label {
				font-size: 1rem;
				font-weight: 700;
				text-transform: none;
			}

			img {
				width: 25px;
				max-width: 100%;
				height: auto;
				display: block;
			}
		}
	}
}