.ce-button-group {
	width: 100%;
	display: block;

	.neos-contentcollection {
		display: flex;
		align-items: center;
		flex-grow: 1;
	}

	&.align-left .neos-contentcollection {
		justify-content: flex-start;
	}

	&.align-center .neos-contentcollection {
		justify-content: center;
	}

	&.align-right .neos-contentcollection {
		justify-content: flex-end;
	}
}

.ce-button {
	margin-right: 0.5rem;
	flex-basis: auto;

	a {
		display: block;
		float: left;
		padding: 1rem 2rem;
		text-align: center;
		font-weight: 700;
		font-size: 0.95rem;
		letter-spacing: 0.01rem;
		text-transform: uppercase;
		line-height: 1;
		opacity: 1;
		@include radius(100px);
		@include transition(all 0.35s $easing--in-out);

		&:hover {
			opacity: 0.75;
			@include transition(all 0.35s $easing--in-out);
		}
	}
}