form {
	@extend %block;
	margin-top: 0.75rem;
	margin-left: -1%;

	fieldset {
		@extend %block;
		border: none;
		padding: 0;
		margin: 0;

		h2 {
			@extend %block;
			float: left;
			font-size: 1.75rem;
			margin-bottom: 0.75rem;
		}

		legend {
			width: 98%;
			height: auto;
			display: block;
			float: left;
			margin: 0.75rem 1%;
			font-size: 1.2rem;
			font-weight: 700;
		}

		p {
			width: 98%;
			height: auto;
			display: block;
			float: left;
			margin: 0.75rem 1%;
			line-height: 1.35;
			font-size: 0.9rem;
			font-family: $font--base-family;

			@include for-size(md) {
				font-size: 1rem;
			}

		}

		.clearfix {
			width: 98%;
			height: auto;
			display: block;
			float: left;
			margin: 0 1% 1.5rem;

			&:last-child {
				margin-bottom: 0;
			}

			&.w50 {
				width: 48%;
				height: auto;
				display: inline;
			}

			&.w25 {
				width: 23%;
				height: auto;
				display: inline;
			}

			&.w75 {
				width: 73%;
				height: auto;
				display: inline;
			}

			label {
				@extend %block;
				font-size: 0.9rem;

				margin-bottom: 0.25rem;
				color: $color--dark;
			}

			.input {
				@extend %block;
				float: left;

				input, textarea {
					@extend %block;
					float: left;
					background: transparent;
					border: 1px solid $color--dark;
					color: $color--dark;
					padding: 0.75rem 1rem;
					font-weight: 500;
					font-size: 0.85rem;
					@include radius(0);

					&:focus {
						border: 1px solid $color--primary;
					}

					&.error {
						border: 1px solid $color--secondary;
					}
				}

				textarea {
					width: 100%;
					min-width: 100%;
					max-width: 100%;
					min-height: 300px;
					max-height: 800px;
					resize: none;
				}

				ul.inputs-list {
					@extend %block;
					float: left;


					li {
						list-style: none;
						display: inline;
						float: left;
						margin-right: 0.6rem;

						&:last-child {
							margin-right: 0;
						}

						label {
							position: relative;
							display: block;
							float: left;

							&.active {
								span {
									background: $color--primary;
									color: $color--light;
									border: 1px solid $color--primary;
								}
							}

							input {
								display: none;
							}

							span {
								display: inline;
								float: left;
								font-weight: 700;
								font-size: 0.85rem;
								padding: 0.6rem 1.5rem;
								background: transparent;
								color: $color--dark;
								border: 1px solid $color--dark;
								cursor: pointer;
								@include transition(all 0.35s $easing--in-out);


								&:hover {
									background: $color--primary;
									color: $color--light;
									border: 1px solid $color--primary;
									@include transition(all 0.35s $easing--in-out);
								}
							}
						}
					}
				}

				label.add-on {
					width: auto;
					display: inline;

					input.add-on {
						width: auto;
						display: inline;
						margin-top: -19px;
						margin-left: -19px;
					}
				}
			}
		}
	}

	.error {
		.help-inline {
			display: none;
		}
	}

	.form-navigation {
		@extend %block;
		float: left;
		margin-top: 1.5rem;

		ul {
			@extend %block;
			float: left;
			display: flex;
			justify-content: center;
			align-items: center;

			li {
				list-style: none;
				display: inline;

				button {
					display: block;
					background: $color--dark;
					color: $color--light;
					padding: 0.75rem 1.75rem;
					font-weight: 700;
					font-size: 1rem;
					cursor: pointer;
					border: none;
					@include transition(all 0.35s $easing--in-out);

					&:active {
						background-color: $color--primary;
						@include transition(all 0.35s $easing--in-out);
					}

					&:hover {
						background-color: $color--primary;
						@include transition(all 0.35s $easing--in-out);
					}
				}
			}
		}
	}
}